'use client';

import { FC, useEffect, useRef, ReactNode } from 'react';
import styles from './Modal.module.scss';
import { useTrapFocus } from '../../../shared/hooks/useTrapFocus';
import { IconClose } from '@maverick/icons/dist/IconClose';
import { Icon } from '../Icon';

export type ModalButton = {
	label: string;
	onClick?: () => void;
};

export interface ModalProps {
	text?: string;
	htmlString?: string;
	primaryButton?: ModalButton;
	id: string;
	secondaryButton?: ModalButton;
	title?: string;
	element?: JSX.Element;
	outsideClick?: () => void;
	isVisible?: boolean;
	onClose?: () => void;
	content?: ReactNode;
	status?: number;
	showCloseButton?: boolean;
}

export const Modal: FC<ModalProps> = ({
	text,
	htmlString,
	primaryButton,
	id,
	secondaryButton,
	title,
	element,
	outsideClick,
	isVisible,
	onClose,
	content,
	status,
	showCloseButton = true,
}) => {
	const mainButton = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (isVisible) {
			document.body.style.overflow = 'hidden';
			window.dispatchEvent(new CustomEvent('scroll-block'));
			mainButton.current?.focus();
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
			window.dispatchEvent(new CustomEvent('scroll-unblock'));
		};
	}, [isVisible]);

	const modal = useRef<HTMLDivElement>(null);
	useTrapFocus(modal);

	const onClickButton = (onClick?: () => void) => {
		onClose?.();
		onClick?.();
	};

	const handleOutsideClick = () => {
		onClose?.();
		outsideClick?.();
	};

	return (
		<div className={styles['modalOverlay']} data-testid='modal-overlay' onClick={handleOutsideClick}>
			<div
				aria-live='polite'
				className={styles['modalContainer']}
				ref={modal}
				role='alert'
				id={id ?? 'modal-container'}
				data-testid={id ?? 'modal-container'}
				onClick={(e) => e.stopPropagation()}
			>
				{showCloseButton && (
					<button
						className={styles['modalCloseIcon']}
						onClick={() => onClose?.()}
						data-testid={'close-button-modal-container'}
					>
						<Icon icon={IconClose} />
					</button>
				)}
				<div>
					<div className={styles['title']}>{title}</div>
					{!!status && status >= 400 && <div className={styles['status']}>Error Code: {status}</div>}
				</div>
				<div aria-live='polite'>
					{text && (
						<div className={styles['text']}>
							{text}
						</div>
					)}
					{htmlString && (
						<p className={styles['text']} dangerouslySetInnerHTML={{ __html: htmlString }} />
					)}
				</div>
				{!!content && <div>{content}</div>}
				{!!element && <div>{element}</div>}
				<div className={styles['buttonsContainer']}>
					{!!primaryButton && (
						<button
							className={styles['button']}
							id='primary-modal'
							ref={mainButton}
							onClick={() => onClickButton(primaryButton.onClick)}
						>
							{primaryButton.label}
						</button>
					)}
					{!!secondaryButton && (
						<button
							className={styles['button']}
							id='secondary-modal'
							ref={mainButton}
							onClick={() => onClickButton(secondaryButton.onClick)}
						>
							{secondaryButton.label}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
